import React from "react";
import Spinner from "../spinner.svg";

const Loading = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={Spinner} alt="Loading..." className="animate-spin fill-black" width="24" height="24" />
    </div>
  );
};

const Skeleton = () => {
  return (
    <div className="w-full justify-center animate-pulse bg-gray-100 h-20" />
  );
};

export { Loading, Skeleton };
