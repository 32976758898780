import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const rootElement = document.getElementById("root");

// Dynamically import index.css
import("./index.css").then(() => {
  ReactDOM.createRoot(rootElement).render(
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        {/* <Suspense fallback={null}> */}
          <App />
        {/* </Suspense> */}
      </React.StrictMode>
    </I18nextProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
